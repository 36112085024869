/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ložnice"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-imrmqo css-42e4bw --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Ložnice</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gldri53pug"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":678}}>
              
              <Title className="title-box" style={{"maxWidth":930}} content={"Moderní ložnice zdaleka neznamená pouze minimalistický nebo extravagantní styl."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":649}} content={" Vaši ložnici můžete zařídit útulně a příjemně, a přesto se držet těch nejnovějších trendů ve vybavení. Vyrobíme pro vás postele, čalouněná čela, noční stolky i skříně pro spokojené snění a příjemné probouzení."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--80" name={"1d8u6246rn5"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/6bae9bfa0a2641f9a9ad814ee46af0e5_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/6bae9bfa0a2641f9a9ad814ee46af0e5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/6bae9bfa0a2641f9a9ad814ee46af0e5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/6bae9bfa0a2641f9a9ad814ee46af0e5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/6bae9bfa0a2641f9a9ad814ee46af0e5_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/6518a29be26941d2b7c86e6f8da2a9a1_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/6518a29be26941d2b7c86e6f8da2a9a1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/6518a29be26941d2b7c86e6f8da2a9a1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/6518a29be26941d2b7c86e6f8da2a9a1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/6518a29be26941d2b7c86e6f8da2a9a1_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/919fa3d92b6d402ba81bfa1e6fa4f4bb_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/919fa3d92b6d402ba81bfa1e6fa4f4bb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/919fa3d92b6d402ba81bfa1e6fa4f4bb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/919fa3d92b6d402ba81bfa1e6fa4f4bb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/919fa3d92b6d402ba81bfa1e6fa4f4bb_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/717cfc6d79ff4b12a204e028940cf253_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/717cfc6d79ff4b12a204e028940cf253_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/717cfc6d79ff4b12a204e028940cf253_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/717cfc6d79ff4b12a204e028940cf253_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/717cfc6d79ff4b12a204e028940cf253_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/9946e23ded6c4e0ebbd758a026d726c9_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/9946e23ded6c4e0ebbd758a026d726c9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/9946e23ded6c4e0ebbd758a026d726c9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/9946e23ded6c4e0ebbd758a026d726c9_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/0af69bf692d745e2894181253ab3c5fc_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/0af69bf692d745e2894181253ab3c5fc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/0af69bf692d745e2894181253ab3c5fc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/0af69bf692d745e2894181253ab3c5fc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/0af69bf692d745e2894181253ab3c5fc_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-imrmqo --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/24a58544a94542acad4e7228f9e76375_r=810__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Máte zájem o ložnici?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--center mt--16" use={"page"} href={"/kontakt"} content={"Kontaktovat&nbsp; ➔"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3 flex--center" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500" content={"<span style=\"color: var(--color-dominant);\">Living4Handy s.r.o.</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":224}} content={"<span style=\"color: rgb(220, 221, 221);\">Výrobce kvalitních kuchyní a nábytku na míru pro Vaši spokojenost. Realizujeme  po Praze, Středočeském kraji i okolí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":473}} content={"<span style=\"color: rgb(248, 248, 248);\">IČ 25731491<br>DIČ CZ25731491<br><br>Společnost je vedená u Městského soudu v Praze, spisová značka C 65152</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500 lh--14" content={"<span style=\"color: var(--color-dominant);\">+420 777 206 285<br>info@living4handy.cz<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: rgb(220, 221, 221);\">Living4Handy s.r.o.\n<br>Luženská 2801<br>&nbsp;Rakovník</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}